<template>
  <div>
    <div v-if="centerDialogVisible">
      <el-dialog
        :close-on-click-modal="false"
        title="修改登录密码"
        :visible.sync="centerDialogVisible"
        height="310px"
        width="480px"
        center
      >
        <div class="phonecenter">
          <p class="title">安全验证</p>
          <p class="title2">短信验证码将发送至手机 {{ phone }}</p>
          <el-input
            placeholder="请输入短信验证码"
            v-model="phonevalue"
            oninput="value=value.replace(/[^\d]/g,'')"
          >
            <template slot="append"
              ><span v-if="verificationflag" class="phone" @click="sendout"
                >短信验证码</span
              >
              <span v-else class="phone">{{ verification }}</span>
            </template>
          </el-input>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary" class="determine" :loading="loadingbol" @click="Phonealter"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div v-if="alterpass" class="paws">
      <el-dialog
        :close-on-click-modal="false"
        title="修改登录密码"
        :visible.sync="alterpass"
        width="480px"
        center
      >
        <div class="phonecenter">
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            label-position="top"
            label-width="80px"
            :rules="rules"
          >
            <el-form-item
              label="设置新的登录密码"
              prop="input1"
              autocomplete="off"
            >
              <el-input
              :type="eseybol1?'password':'text'"
                v-model="ruleForm.input1"
                placeholder="请设置登录密码，8-20位，大小写字母、数字或符号3种组合"
              >
              <img @click="esefn1()" slot="suffix" style="cursor:pointer;width:16px;height:16px;vertical-align: middle;" :src="eseyImg1" alt="">
              </el-input>
            </el-form-item>
            <el-form-item prop="input2" autocomplete="off">
              <el-input
                 :type="eseybol2?'password':'text'"
                v-model="ruleForm.input2"
                placeholder="请再次输入新的登录密码"
              >
                 <img @click="esefn2()" slot="suffix" style="cursor:pointer;width:16px;height:16px;vertical-align: middle;" :src="eseyImg2" alt="">
              </el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="info" @click="alterpass = false">取 消</el-button>
          <el-button
            type="primary"
            class="determine"
            :loading="submitbol"
            @click="modifypsw('ruleForm')"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import md5 from 'md5'
import eyes1 from '../assets/eyes1.png'
import eyes2 from '../assets/eyes2.png'
let timer = null
export default {
  mounted(){

  },
  data() {
    var onepassword = (rule, value, callback) => {
      let regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[~@#$%\\*-\\+=:,\\?\\[\]\\{}]).{8,20}$/
      if (value === '') {
        callback(new Error('密码不能为空'))
      } else if (!regex.test(value)) {
        callback(
          new Error('请设置登录密码，8-20位，大小写字母、数字或符号3种组合')
        )
      } else {
        // this.$refs.ruleForm.validateField('input2');
        callback()
      }
    }
    var twopassword = (rule, value, callback) => {

      if (value === this.ruleForm.input1) {
        callback()
      } else {
        callback(new Error('两次输入密码不一样'))
      }
    }
    return {
      rules: {
        input1: [
          {
            min: 8,
            message: '密码密码不能少于8位',
            trigger: 'change'
          },
          { validator: onepassword, trigger: 'blur' },
          {
            max: 20,
            message: '密码密码不能多于20位',
            trigger: 'change'
          }
        ],
        input2: [{ validator: twopassword, trigger: 'blur' }],
      },
       //电话
       phone:'',
      // 弹框按钮显示隐藏
      centerDialogVisible: false,
      alterpass: false,
      //   短信验证
      phonevalue: '',
      ruleForm: {
        input1: '',
        input2: '',
      },
      verification: '',
      verificationflag: true,
      data:{},
      // 节流
    throttleSendout:true,
    loadingbol:false,
    submitbol:false,
    // 密码显示隐藏
     eseyImg1:eyes1,
     eseybol1:true,
     eseyImg2:eyes1,
     eseybol2:true,
    }

  },
  computed: {
    mobile: function() {
      return this.$store.state.user.mobile
    },
    zone:function() {
        return this.$store.state.user.zone
    }
  },
  methods: {
    changePassword(val,cen) {
      this.centerDialogVisible = true
      let arr = val.split('')
      arr.splice(3, 4, '****')
      this.phone = arr.join('')
       this.data=cen

    },
    // 修改密码
    modifypsw(val) {
      this.$refs[val].validate((valid) => {
        if(valid) {
            let params=null;

                params={
                    newPwd:md5(this.ruleForm.input1),

                }
           this.submitbol=true;
            this.$post('/user/modifyPwd',params).then((res)=>{
                this.submitbol=false;
               if(res.code==0){
                   this.$popup({
                    title: '成功',
                    msg:res.msg,
                    type: 'success',
                    });
                  this.alterpass=false;
                   this.$refs[val].resetFields();
               }
            })
        }
      })
    },
    // 短信验证弹框确定按钮
    Phonealter() {
        this.loadingbol=true;
      // 短信校验
      this.$post('/api/sms/validate', {
        zone: this.zone,
        phone: this.mobile,
        type: 6,
        acode: this.phonevalue
      }).then((res) => {
           this.loadingbol=false;
        if(res.httpStatus==0||res.code===0){
        this.verificationflag = true
      this.centerDialogVisible = false
      this.alterpass = true
      clearInterval(timer)
       this.phonevalue=""
        }
      })
    },
    // 发送短短信
    sendout() {
       if(!this.throttleSendout){
           return
       }
       this.throttleSendout=false
      // 发送短信验证码
      this.$post('/api/sms/send', {
        zone: this.zone,
        phone: this.mobile,
        type: 6,
        cs: new Date().valueOf()
      }).then((res) => {
          this.throttleSendout=true;
        if(res.code==0){
           this.verification = '剩余60s'
        clearInterval(timer)
        let num = 59
        this.verificationflag = false
        timer = setInterval(() => {
          if (num <= 0) {
            clearInterval(timer)
            this.verificationflag = true
            this.phonevalue=""
          } else {
            this.verification = '剩余' + num-- +'s'
          }
        }, 1000)
        }
      })
    },
    //密码显示隐藏
    esefn1(){
        this.eseybol1=!this.eseybol1;
        if(this.eseybol1){
            this.eseyImg1=eyes1
        }else{
             this.eseyImg1=eyes2
        }
    },
    esefn2(){
        this.eseybol2=!this.eseybol2;
        if(this.eseybol2){
            this.eseyImg2=eyes1
        }else{
             this.eseyImg2=eyes2
        }

    }
  },
  //   弹框隐藏后密码隐藏---不可见
  watch: {
      alterpass(newval){
       if(!newval){
            this.eseybol2=true;
             this.eseybol1=true;
             this.eseyImg1=eyes1;
              this.eseyImg2=eyes1;
       }
      }
  }
}
</script>
<style scoped lang="scss">
// @import '../styles/initialization.scss';
::v-deep .el-dialog__header{
   padding-top: 30px;
  .el-dialog__title {
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height:30px;
}

}


.paws {
  ::v-deep .el-dialog__body {
    padding-bottom: 0px;
  }
}
.phonecenter {
  padding: 0px 23px;
  .title {
    font-size: 14px;
    font-family: PingFangHK-Semibold, PingFangHK;
    font-weight: 600;
    color: #333333;
    line-height: 20px;
     margin:0px;
  }
  .title2{
     margin-top: 7px;
     margin-bottom:14px;
  }
  .el-input {
    // border-bottom: 1px solid;
    .el-input__inner {
      border: none !important;

    }
    ::v-deep .el-input__inner {
    &::placeholder {
         width:350px;
         font-size: 14px;
        white-space: nowrap;
         overflow: hidden;
        text-overflow:ellipsis

    }
  }

    .phone {
      height: 16px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff004d;
      line-height: 16px;
      cursor: pointer;
    }
  }
}
::v-deep .el-dialog__footer{
    padding-bottom: 0px;
 .dialog-footer {
  .el-button {
    width: 190px;
    height: 40px;
    margin-bottom: 39px;
  }
  .determine {
    margin-left: 20px;
  }
}
}

</style>
